.Polaris-DropZone--hasError::after {
  border-color: #ee1c1c !important;
}
.Polaris-DropZone--hasError {
  background: #fff4f4 !important;
}

.Polaris-DropZone--hasError .Polaris-DropZone-FileUpload__Action {
  background-color: #ffd9d9;
  color: #ee1c1c;
}
